<template>
  <CRow>
    <CCol col="12" class="mb-2">
      <CButton
        class="float-right"
        color="success"
        @click="() => $router.push('/customlinks/0')"
      >
        Nuevo enlace
      </CButton>
    </CCol>

    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader> Enlaces </CCardHeader>
        <CCardBody>
          <div class="text-center">
            <CSpinner v-if="loading" variant="grow" size="lg" />
          </div>

          <CDataTable
            v-if="!loading"
            hover
            striped
            sorter
            :items="items"
            :fields="fields"
            clickable-rows
            @row-clicked="rowClicked"
          >
            <template #begin_at="data">
              <td>
                {{ data.item.begin_at ? data.item.begin_at : "-" }}
              </td>
            </template>
            <template #paymentCode="data">
              <td>
                {{ data.item.paymentCode ? data.item.paymentCode : "-" }}
              </td>
            </template>
            <template #end_at="data">
              <td>
                {{ data.item.end_at ? data.item.end_at : "-" }}
              </td>
            </template>
            <template #created_at="data">
              <td>
                {{ data.item.created_at | dateFormat }}
              </td>
            </template>
          </CDataTable>

          <CPagination
            v-if="!loading"
            :activePage.sync="page"
            :pages="lastPage"
            size="sm"
            align="center"
            @update:activePage="pageChange"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Resource from "@/api/resource";
import moment from "moment";

const resource = new Resource("paylink");

export default {
  name: "PayLink",
  data() {
    return {
      items: [],
      loading: false,
      fields: [
        { key: "name", label: "Nombre" },
        { key: "begin_at", label: "Inicia" },
        { key: "end_at", label: "termina" },
        { key: "max_uses", label: "Usos" },
        { key: "used", label: "Usados" },
      ],
      page: 1,
      perPage: 10,
      lastPage: 1,
    };
  },
  filters: {
    dateFormat: function (value) {
      return value ? moment(value).format("YYYY-MM-DD HH:mm") : "";
    },
  },
  mounted() {
    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    this.getItems();
  },
  methods: {
    async getItems() {
      this.loading = true;
      try {
        const response = await resource.list({
          page: this.page,
          perPage: this.perPage,
        });
        if (response.status == 200) {
          this.items = response.data.data;
          this.lastPage = response.data.last_page;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    getBadge(status) {
      switch (status) {
        case "Activo":
          return "success";
        case "Inactivo":
          return "secondary";
        case "Pending":
          return "warning";
        case "Banned":
          return "danger";
        default:
          "primary";
      }
    },

    rowClicked(item, index) {
      this.$router.push({ path: `customlinks/${item.id}` });
    },

    async pageChange(val) {
      this.$router.push({ query: { page: val } });
      await this.getItems();
      this.page = val;
    },
  },
};
</script>
